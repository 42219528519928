/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useCallback, useMemo, useState, useContext,
} from 'react';
import { Portal } from 'react-portal';
import { useFragment } from '@apollo/client';
import {
    shape, func, string, bool, oneOf,
} from 'prop-types';
import {
    cartType,
    cartInitialType,
    discountConditionsInitialType,
    discountConditionsType,
} from 'types';

import { LocaleContext } from 'app/containers/LocaleProvider';

import {
    UIHeading,
} from 'app/components/ui';
import { dishes as kozelDishes } from 'global/const/kozel';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { DialogAnimated } from 'app/components/Dialog';
import { getAgeGateLocales } from 'app/views/const/getAgeGateLocales';
import { BASKET_ALL_DISHES_FRAGMENT } from '../SingleStepCheckout/graphql/basketDishesFragment.graphql';

import { StepLayout } from '../StepLayout/StepLayout';
import StepPaySubToggleSection from './components/StepPaySubToggleSection';
import StepPayPaymentMethodsSection from './components/StepPayPaymentMethodsSection';
import StepPayCheckSection from './components/StepPayCheckSection';
import StepPayEmailSection from './components/StepPayEmailSection';
import { StepPayButtonContent } from './components/StepPayButtonContent';
import { RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP } from '../stepByStepCheckoutConst';

import './step-payment.scss';


const paymentLocales = {
    ru: {
        headerTitle: 'Оформление заказа',
        title: 'Способ оплаты',
    },
    en: {
        headerTitle: 'Checkout',
        title: 'Payment method',
    },
};


export const StepPayment = React.memo((props) => {
    const {
        renderContext,
        headerTitle: headerTitleProp,
        handleSelectPayment,
        handleOpenNextStep,
        paymentMethod,
        promocodeConditions,
        applyPromocodeHandler,
        handleBlurEmail,
        handleChangeEmail,
        email,
        emailValid,
        hideFoodcard,
        hideOnlinePayment,
        hideCashPayment,
        handlePay,
        subscriptionTypeQuery,
        subscriptionTypeQuery: {
            subscriptionType,
        },
        hideRecurrentPayment,
        cart,
        cart: {
            totals,
        },
        handleOpenDeliveryPricePopup,
        onClickSubscriptionInfoOpen,
        nextStepButtonType,
        subdivision,
    } = props;

    const { locale } = useContext(LocaleContext);
    const l = paymentLocales[locale];
    const headerTitle = headerTitleProp || l.headerTitle;

    /* STATE */

    /**
     * @description Объект нужен для совместимость с check в StepPayCheckSection
     */
    const hiddenElements = useMemo(() => ({
        payment_button_recurrent: hideRecurrentPayment,
        payment_button_online: hideOnlinePayment,
    }), [hideOnlinePayment, hideRecurrentPayment]);

    const [isNextStepLoading, setIsNextStepLoading] = useState(false);
    const [isAgeGatePopupShown, setIsAgeGatePopupShown] = useState(false);

    const { data: basketData } = useFragment({
        from: {
            __ref: `cart:${selectedPeriodVar()}`,
        },
        fragment: BASKET_ALL_DISHES_FRAGMENT,
    });

    const dishIds = useMemo(
        () => basketData?.sections
            ?.map((section) => [...section.items])
            ?.flat()
            ?.map((item) => item.dish_id),
        [basketData],
    );

    /* HADLERS  */

    const handleSubmit = useCallback(() => {
        const basketHasKozelDishes = kozelDishes.some((id) => dishIds.includes(id));
        if (basketHasKozelDishes) {
            setIsAgeGatePopupShown(true);
            return;
        }
        handlePay();
        setIsNextStepLoading(true);
    }, []);

    const onClickSubscriptionInfoOpenWithContext = useCallback((e) => {
        onClickSubscriptionInfoOpen(e, 'step-by-step-checkout');
    }, []);

    const ageGateDialogTexts = getAgeGateLocales()[locale].dialog.success;

    return (
        <StepLayout
            {...props}
            fillContent={false}
            title={headerTitle}
            handleOpenNextStep={nextStepButtonType === 'submit' ? handleSubmit : handleOpenNextStep}
            isNextStepLoading={isNextStepLoading}
            nextStepButtonContent={nextStepButtonType === 'submit' ? (
                <StepPayButtonContent
                    cart={cart}
                    payLoading={isNextStepLoading}
                    paymentMethod={paymentMethod}
                />
            ) : null}
        >
            <div styleName={`payment-step-area-title ${renderContext}`}>
                {renderContext === RENDER_CONTEXT_SINGLE_STEP
                    ? null
                    : (
                        <UIHeading
                            level="3.2"
                            styleName="payment-step-area-title__item"
                        >
                            {l.title}
                        </UIHeading>
                    )}
            </div>
            {!hideRecurrentPayment && (
                <StepPaySubToggleSection
                    handleSelectPayment={handleSelectPayment}
                    subscriptionTypeQuery={subscriptionTypeQuery}
                    onClickSubscriptionInfoOpenWithContext={onClickSubscriptionInfoOpenWithContext}
                />
            )}
            <StepPayPaymentMethodsSection
                handleSelectPayment={handleSelectPayment}
                paymentMethod={paymentMethod}
                totals={totals}
                hideFoodcard={hideFoodcard}
                hideOnlinePayment={hideOnlinePayment}
                hideCashPayment={hideCashPayment}
                subscriptionTypeQuery={subscriptionTypeQuery}
            />

            {renderContext === RENDER_CONTEXT_SINGLE_STEP
                ? null
                : (
                    <div styleName="payment-step-area-background-container">
                        <StepPayCheckSection
                            locale="ru"
                            totals={totals}
                            promocodeConditions={promocodeConditions}
                            applyPromocodeHandler={applyPromocodeHandler}
                            onClickDeliveryPrice={() => {}}
                            cart={cart}
                            hiddenElements={hiddenElements}
                            subscriptionType={subscriptionType}
                            onClickSubscriptionInfoOpenWithContext={onClickSubscriptionInfoOpenWithContext}
                            handleOpenDeliveryPricePopup={handleOpenDeliveryPricePopup}
                            hideRecurrent={hideRecurrentPayment}
                            subdivision={subdivision}
                        />
                        <StepPayEmailSection
                            handleBlurEmail={handleBlurEmail}
                            handleChangeEmail={handleChangeEmail}
                            email={email}
                            emailValid={emailValid}
                        />
                    </div>
                )}
            <Portal>
                <DialogAnimated
                    {...ageGateDialogTexts}
                    isOpen={isAgeGatePopupShown}
                    onConfirm={() => {
                        setIsAgeGatePopupShown(false);
                        handlePay();
                        setIsNextStepLoading(true);
                    }}
                    onReject={() => {
                        setIsAgeGatePopupShown(false);
                    }}
                    rejectSpecificHandler={() => {
                        window.location = 'https://elementaree.ru';
                    }}
                />
            </Portal>
        </StepLayout>
    );
});

StepPayment.propTypes = {
    renderContext: oneOf([RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP]),
    headerTitle: string,

    cart: shape(cartType),
    promocodeConditions: shape(discountConditionsType),
    handleOpenDeliveryPricePopup: func.isRequired,
    onClickSubscriptionInfoOpen: func.isRequired,
    subscriptionTypeQuery: shape({
        subscriptionType: string.isRequired,
    }).isRequired,
    handleSelectPayment: func.isRequired,
    applyPromocodeHandler: func.isRequired,
    handleBlurEmail: func.isRequired,
    handleChangeEmail: func.isRequired,
    paymentMethod: string,
    hideFoodcard: bool.isRequired,
    hideOnlinePayment: bool.isRequired,
    hideCashPayment: bool.isRequired,
    handlePay: func.isRequired,
    email: string,
    emailValid: bool,
    hideRecurrentPayment: bool,
    nextStepButtonType: oneOf(['regular', 'submit']),
    handleOpenNextStep: func.isRequired,
    subdivision: string.isRequired,
};
StepPayment.defaultProps = {
    renderContext: RENDER_CONTEXT_STEP_BY_STEP,
    headerTitle: null,

    cart: cartInitialType,
    promocodeConditions: discountConditionsInitialType,
    paymentMethod: 'default',
    email: '',
    emailValid: true,
    hideRecurrentPayment: false,
    nextStepButtonType: 'submit',
};
