import { makeVar } from '@apollo/client';
import { getInitilaSubsctiptionType } from 'app/const/subscription';

const initCheckoutData = {
    telephone: null,
    email: null,
    street: null,
    apartment: '',
    floor: '',
    entrance: '',
    day: '',
    timePeriod: 'inside_mkad',
    timeIndex: 0,
    mkad: 'init',
    timeIntervalsInsideBetlwayTitle: '',
    timeIntervalsOutsideBetlwayTitle: '',
    subdivision: null,
    isContactlessDeliveryActivated: false,
};
const initInvoiceDeliveryDate = '';

/**
* бэкенд корректно работает с null в качестве дефолтного значения
*/
const initCheckoutDeliveryDate = null;

const initVars = {

    dishItemType: 'small', // small, large
    isBasketFilled: false,

    subscriptionType: getInitilaSubsctiptionType(),

    selectedPeriod: '',
    prevSelectedPeriods: [],
    selectedCategoryId: '',
    selectedCategories: [],
    selectedTags: [],
    /**
     * isSelectedDefaultFilters - флаг, который вычисляется из остальных выбранных фильтров
     * Дефолтный выбор фильтров - все категории и ни одного тега
     */
    isSelectedDefaultFilters: true,
    /**
        isUserChangesTags - параметр для определения, менял ли пользователь теги
        Он влияет на то, будут ли применяться теги из url-параметров к фильтру
        При первой загрузке страницы isUserChangesTags == false, тогда в state должны сохраниться теги из url-а
        Если пользователь меняет теги, то isUserChangesTags становится true, и тогда в url устанавливаются теги из state-а
    */
    isUserChangesTags: false,

    dishes: [],
    // dishItemType: 'small', // small, large DISHITEM
    /**
        basketState - параметр для определения, заходил ли пользователь в корзину (Basket или DeliveryBasket)
        Он влияет на то, будет ли работать анимация появления элементов в корзине
        При первой загрузке корзины basketState == false
    */
    basketState: 'empty', // basketViewed
    paymentMethod: 'test', // online, cash
    promocode: '',
    deliveries: [],
    checkoutData: null, // сохранение введенных данных при возврате в корзину, чтобы заполнить их переходе в чекаут
    checkoutDeliveryDate: null, // Выбранная дата доставки, нужная для передачи в BasketQuery
};

const initDeliveries = [{
    id: '',
    dates: '',
    addressData: {
        value: '',
        city: '',
        house: '',
        entrance: '',
        floor: '',
        flat: '',
        comment: '',
        beltwayHit: '',
        beltwayHitValue: '',
        beltwayDistance: '',
    },
    intervals: {
        insideBeltway: {
            hourFrom: '',
            hourTo: '',
            enabled: '',
        },
        outsideBeltway: {
            hourFrom: '',
            hourTo: '',
            enabled: '',
        },
    },
    selectedDate: '',
    selectedInterval: {
        hourFrom: '',
        hourTo: '',
    },
    phone: '',
    name: '',
    validationState: {
        changedFields: '',
        error: '',
    },
    periodBorders: {
        start: '',
        end: '',
    },
    loading: false,
    notifications: '',
    isReferralBonusApplied: false,
}];

// DISH_ITEM_TYPE
export const dishItemTypeVar = makeVar(initVars.dishItemType);

// BASKET_FILLED_STATUS
export const isBasketFilledVar = makeVar(initVars.isBasketFilled);

export const selectedPeriodVar = makeVar(initVars.selectedPeriod);
export const prevSelectedPeriodsVar = makeVar(initVars.prevSelectedPeriods);
export const selectedCategoryIdVar = makeVar(initVars.selectedCategoryId);
export const selectedTagsVar = makeVar(initVars.selectedTags);
export const selectedCategoriesVar = makeVar(initVars.selectedCategories);
export const isUserChangesTagsVar = makeVar(initVars.isUserChangesTags);
export const isSelectedDefaultFiltersVar = makeVar(initVars.isSelectedDefaultFilters);

// GET_SUBSCRIPTION_TYPE
export const subscriptionTypeVar = makeVar(initVars.subscriptionType);

// GET_PAYMENT_METHOD
export const paymentMethodVar = makeVar(initVars.paymentMethod);

// GET_CHECKOUT_DATA
export const checkoutDataVar = makeVar(initCheckoutData);

// GET_INVOICE_DELIVERY_DATE
export const invoiceDeliveryDateVar = makeVar(initInvoiceDeliveryDate);

// CHECKOUT_DELIVERY_DATE_QUERY
export const checkoutDeliveryDateVar = makeVar(initCheckoutDeliveryDate);

export const promocodeVar = makeVar(initVars.promocode);

// GET_DELIVERIES initDeliveries)
export const profileDeliveriesVar = makeVar([]);

/* ROOT REACTIVE VARS */

export const tokenVar = makeVar(localStorage.getItem('token'));

export const rootReactiveVars = {
    dishItemType: { read() { return dishItemTypeVar(); } },
    isBasketFilled: { read() { return isBasketFilledVar(); } },

    selectedPeriod: { read() { return selectedPeriodVar(); } },
    prevSelectedPeriods: { read() { return prevSelectedPeriodsVar(); } },
    selectedCategoryId: { read() { return selectedCategoryIdVar(); } },
    selectedTags: { read() { return selectedTagsVar(); } },
    selectedCategories: { read() { return selectedCategoriesVar(); } },
    isUserChangesTags: { read() { return isUserChangesTagsVar(); } },
    isSelectedDefaultFilters: { read() { return isSelectedDefaultFiltersVar(); } },
    subscriptionType: { read() { return subscriptionTypeVar(); } },
    paymentMethod: { read() { return paymentMethodVar(); } },
    checkoutData: { read() { return checkoutDataVar(); } },
    /** @note НЕ ИСПОЛЬЗУЕТСЯ */
    invoiceDeliveryDate: { read() { return invoiceDeliveryDateVar(); } },
    checkoutDeliveryDate: { read() { return checkoutDeliveryDateVar(); } },

    promocode: { read() { return promocodeVar(); } },

    deliveries: { read() { return profileDeliveriesVar(); } },

    token: { read() { return tokenVar(); } },
};

/**
 * @description ипользуется в BR-1104 что-бы глобально прокинуть все диши в любой
 * компонент и не делать лишние зыпросы
 */
export const allDishesListVar = makeVar([]);
