/* eslint-disable camelcase */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { UIHeading } from 'app/components/ui';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';
import { BonusesNotifier } from 'app/components/Bonuses';
import { useQuery } from '@apollo/client'; import { isDesktop } from 'app/utils/resolution';

import {
    DishesPrice,
    DeliveryPrice,
    ReferralAmount,
    Promocode,
    Subscription,
    AppliedDiscountAmount,
    SubscriptionAppliedDiscountRow,
    PayByBonuses,
    Total,
} from './BasketCheckRows';

import './basket-check.scss';
import { PromocodeAppliedDiscountRow } from './BasketCheckRows/BasketCheckRowDeliveryDiscountRows';
import { GET_USER } from './graphql/GET_USER.graphql';


export const locales = {
    ru: {
        heading: 'Ваш заказ',
    },
    en: {
        heading: 'Your order',
    },
};


const rowsConfig = [
    {
        id: 'DishesPrice',
        check: () => true,
        Row: DishesPrice,
    },
    {
        id: 'DeliveryPrice',
        check: () => true,
        Row: DeliveryPrice,
    },
    {
        id: 'ReferralAmount',
        check: (props) => {
            const { basket: { totals: { referral_amount: referralAmount } } } = props;
            return referralAmount > 0 && isDesktop();
        },
        Row: ReferralAmount,
    },
    {
        id: 'Subscription',
        check: (props) => {
            const {
                hideRecurrent: hideRecurrentProp,
                hiddenElements: {
                    payment_button_recurrent: hideRecurrent,
                    payment_button_online: hideOnlinePayment,
                },
                basket: { totals: { static_common_price: staticCommonPrice } },
            } = props;

            const isFreeOrder = staticCommonPrice === 0;
            const hideRecurrentPayment = hideRecurrent || hideOnlinePayment || isFreeOrder || hideRecurrentProp;

            return !hideRecurrentPayment;
        },
        Row: Subscription,
    },
    {
        id: 'PayByBonuses',
        check: (props) => {
            const { basket } = props;
            const isRefarralAmount = Boolean(basket?.totals?.referral_amount) && !isDesktop();
            return isRefarralAmount;
        },
        Row: PayByBonuses,
    },
    {
        id: 'Promocode',
        check: (props) => {
            const renderContextsCollection = ['basket', 'stepByStepCheckout', 'singleStepCheckout'];
            const { renderContext } = props;
            return renderContextsCollection.includes(renderContext);
        },
        Row: Promocode,
    },

    {
        id: 'AppliedDiscountAmount',
        check: (props) => {
            const {
                renderContext,
                basket: { totals: { applied_discount_amount: discountAmount } },
            } = props;

            return renderContext === 'delivery-basket' && discountAmount > 0;
        },
        Row: AppliedDiscountAmount,
    },

    /**
    * Закомментировано, т.к. AppliedDiscountAmount включает в себя и скидку по подписке тоже
    */
    // {
    //     id: 'SubscriptionAppliedDiscountRow',
    //     check: (props) => {
    //         const {
    //             renderContext,
    //             basket: { totals: { discount_amount: discountAmount } },
    //         } = props;

    //         return renderContext === 'delivery-basket' && discountAmount > 0;
    //     },
    //     Row: SubscriptionAppliedDiscountRow,
    // },
    // {
    //     id: 'PromocodeAppliedDiscountRow',
    //     check: (props) => {
    //         const {
    //             renderContext,
    //         } = props;

    //         return renderContext === 'stepByStepCheckout';
    //     },
    //     Row: PromocodeAppliedDiscountRow,
    // },

    {
        id: 'Total',
        check: () => true,
        Row: Total,
    },
];

export const BasketCheck = ({
    locale,
    subdivision = 'RU-MOW',
    ...restProps
}) => {
    const { data } = useQuery(GET_USER);

    // const { cashback_new_users } = useContext(abTestDataContext);

    // /* SELECTORS */
    // const isCashbackTestGroup = useMemo(
    //     () => (cashback_new_users === 'with_cashback'),
    //     [cashback_new_users],
    // );

    const isUserAuthed = useMemo(
        () => Boolean(data.user),
        [data.user],
    );

    const rows = useMemo(
        () => rowsConfig.filter((rowData) => rowData.check({
            subdivision,
            isUserAuthed,
            // isCashbackTestGroup,
            ...restProps,
        })),
        [restProps, isUserAuthed, subdivision],
    );

    // const isBonusesNotifier = useMemo(
    //     () => {
    //         const isAmount = restProps?.basket?.totals?.cashbackReward;
    //         return isCashbackTestGroup && isUserAuthed && isAmount && !isDesktop();
    //     }, [isUserAuthed, restProps?.basket?.totals?.cashbackReward, isCashbackTestGroup],
    // );

    return (
        <div styleName="basket-check__root">
            <UIHeading level="3.2" styleName="basket-check__heading">
                {locales[locale].heading}
            </UIHeading>
            {/* {!isUserAuthed && (
                <a href="/auth">
                    <div styleName="bonuses-notifier__heading-wrapper">
                        <BonusesNotifier isUserAuthorized={data.user !== null} />
                    </div>
                </a>
            )} */}
            <div>
                {rows.map(({ id, Row }) => (
                    <div styleName="basket-check__item" key={id}>
                        <Row
                            locale={locale}
                            subdivision={subdivision}
                            {...restProps}
                        />
                    </div>
                ))}
            </div>
            {/* {isBonusesNotifier && (
                <div styleName="bonuses-notifier__bottom-wrapper">
                    <BonusesNotifier
                        isUserAuthorized={isUserAuthed}
                        bonusesAmount={restProps?.basket?.totals?.cashbackReward}
                    />
                </div>
            )} */}
        </div>
    );
};

BasketCheck.propTypes = {
    locale: PropTypes.string.isRequired,

    basket: PropTypes.shape({
        totals: PropTypes.shape({
            total_price: PropTypes.number.isRequired,
            delivery_price: PropTypes.number.isRequired,
            referral_amount: PropTypes.number.isRequired,
            total_common_price: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,

    hiddenElements: PropTypes.shape({
        payment_button_recurrent: PropTypes.bool,
        payment_button_online: PropTypes.bool,
    }).isRequired,

    subdivision: PropTypes.string,
    onClickDeliveryPrice: PropTypes.func.isRequired,
};
