/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable quote-props */
import React from 'react';

import { dishes as hpDishes } from 'global/const/hp';
import { dishes as christmasDishes } from 'global/const/christmas';
import { dishes as maslenitsaDishes } from 'global/const/maslenitsa';
import { dishes as sweetsDishes } from 'global/const/sweets';
import { dishes as filmDishes } from 'global/const/film';


import { availableBasketDishIds as marchDishes } from 'global/const/march';
import {
    speed,
    school,
    veg,
    forkids,
    hits,
    creativity,
    guests,
    green,
    hp,
    christmas,
    march,
    maslenitsa,
    dacha,
    film,
} from 'app/assets/dishesSelections/dishesSelections';

import {
    CATEGORY_BREAKFASTS,
    CATEGORY_SOUPS,
    CATEGORY_MAIN,
    CATEGORY_SALADS,
    CATEGORY_SNACKS,
    CATEGORY_DESSERT,
    CATEGORY_UPSELL,
    CATEGORY_FARM_PRODUCTS,
    CATEGORY_TITLES,
} from 'app/const/categories';
import {
    allDishes as kidsDishes,
    SCHOOL_TAG,
} from '../../../../landings/promo-checkout/src/pages/kids/build-config';
import { allDishes as hitsDishes } from '../../../../landings/promo-checkout/src/pages/hits/build-config';
import { allDishes as creativityDishes } from '../../../../landings/promo-checkout/src/pages/creativity/build-config';
import { allDishes as guestsDishes } from '../../../../landings/promo-checkout/src/pages/guests/build-config';

/* TYPES */
import { TActiveSelectionKeys } from '../banners/MenuSelectionsMobileSlider/selections.record';


type TDisheSelectionMeta = {
    title: React.ReactElement | string,
    backgroundImagePath: string,
    description: React.ReactElement | string,
    type: string,
    bgSize: 'm-small',
    dishes?: Array<string>,
    additionalTag?: string, // тег, дополняющий список дишей из dishes
    categoryId?: string
}

type TDishesSelectionRecord = Record<TActiveSelectionKeys, TDisheSelectionMeta>

// @ts-ignore
const DishesSelectionRecord: TDishesSelectionRecord = {
    film: {
        title: <>Дома,<br />как в кино</>,
        description: (
            <>
                - Заказывайте блюда из любимых фильмов и сериалов
                <br />
                - Получайте бесплатную подписку на видеосервис Смотрёшка
                <br />
                - И участвуйте в розыгрыше ТЕЛЕВИЗОРА!
                <br />
                <a className="link" href="/film">Подробнее</a>
            </>
        ),
        backgroundImagePath: film.m,
        type: 'film',
        bgSize: 'm-small',
        dishes: filmDishes,
    },
    kids: {
        title: <>Детское<br />меню</>,
        description: <>
            Хиты всех детей с&nbsp;4&nbsp;лет (и&nbsp;до&nbsp;99!), чтобы творить вместе. А&nbsp;еще в&nbsp;наборах спрятаны раскраски или наклейки!
        </>,
        backgroundImagePath: forkids.m,
        type: 'kids',
        bgSize: 'm-small',
        dishes: kidsDishes,
        additionalTag: SCHOOL_TAG,
    },
    '1220': {
        title: '',
        backgroundImagePath: green.m,
        description: (<>Лёгкий подход к&nbsp;лёгкой еде: вкусные сбалансированные блюда, которые просто готовить</>),
        type: 'lite',
        bgSize: 'm-small',
    },
    '1809': {
        title: <>Готовим<br /> за 15 мин!</>,
        backgroundImagePath: speed.m,
        description: <>Быстрые гарниры и&nbsp;максимально подготовленные продукты, соусы уже сварены нами.</>,
        type: 'fast',
        bgSize: 'm-small',
    },
    '1218': {
        title: <>Вег-меню<br />Лучшее</>,
        backgroundImagePath: veg.m,
        description: <>
            Популярные блюда, адаптированные под рецепты без мяса и&nbsp;рыбы: больше растительного белка в&nbsp;составе.
        </>,
        type: 'veg',
        bgSize: 'm-small',
    },
    soups: {
        title: <>Супы<br />за 10-15 минут</>,
        backgroundImagePath: '',
        description: '',
        type: 'soups',
        bgSize: 'm-small',
        categoryId: CATEGORY_SOUPS,
    },
    hits: {
        title: <>Хиты<br />вне времени</>,
        description: (
            <>
                Начните знакомство с&nbsp;этих блюд: самые популярные рецепты, которые не&nbsp;оставят равнодушными
            </>
        ),
        backgroundImagePath: hits.m,
        type: 'hits',
        bgSize: 'm-small',
        dishes: hitsDishes,
    },
    creativity: {
        title: <>Время для<br />творчества</>,
        description: (
            <>
                Создавайте новые семейные традиции: наборы, чтобы провести интересный выходной дома
            </>
        ),
        backgroundImagePath: creativity.m,
        type: 'creativity',
        bgSize: 'm-small',
        dishes: creativityDishes,
    },

    guests: {
        title: <>К приходу<br />гостей</>,
        description: (
            <>
                Идеальный вариант, когда шумно отмечаете праздники или&nbsp;зовете близких в&nbsp;гости
            </>
        ),
        backgroundImagePath: guests.m,
        type: 'guests',
        bgSize: 'm-small',
        dishes: guestsDishes,
    },
    'hp': {
        title: (
            <>
                Меню
                <br />
                из Хогвартса
            </>
        ),
        description: (
            <>
                После проработок этого меню нам с&nbsp;командой захотелось еще раз пересмотреть все фильмы о&nbsp;Гарри Поттере. Присоединяйтесь! Оставьте на&nbsp;время дела маглов. Добро пожаловать во&nbsp;вселенную Гарри Поттера!
            </>
        ),
        backgroundImagePath: hp.m,
        type: 'hp',
        bgSize: 'm-small',
        dishes: hpDishes,
    },
    ny: {
        title: <>ny</>,
        description: '',
        backgroundImagePath: '',
        type: 'ny',
        bgSize: 'm-small',
        dishes: [],
    },
    christmas: {
        title: '',
        description: 'Праздники продолжаются вместе с рождественским меню от Elementaree! Попробуйте уютные основные блюда, приготовьте классические салаты за 15 минут или устройте день выпечки.',
        backgroundImagePath: christmas.m,
        type: 'christmas',
        bgSize: 'm-small',
        dishes: christmasDishes,
    },
    march: {
        title: <>Великие<br />женщины</>,
        description: (
            <>
                Блюда по ресторанным рецептам: отборные мясо и рыба, авторские соусы
                и маринады, подача от шефа.
            </>
        ),
        backgroundImagePath: march.m,
        type: 'march',
        bgSize: 'm-small',
        dishes: marchDishes,
    },
    maslenitsa: {
        title: <>Масленица</>,
        description: (
            <>
                Сытные или сладкие, на&nbsp;завтрак или на&nbsp;ужин, с&nbsp;готовыми блинами или с&nbsp;самодельными&nbsp;&mdash; мы&nbsp;подготовили все варианты для вкусной Масленицы!
            </>
        ),
        backgroundImagePath: maslenitsa.m,
        type: 'maslenitsa',
        bgSize: 'm-small',
        dishes: maslenitsaDishes,
    },
    sweets: {
        title: <>Печём вместе</>,
        description: (
            <>
                Печь торты и печенье — умиротворяющие домашнее развлечения. Одинаково хороши и с детьми, и вдвоем, и как соло-медитация.
            </>
        ),
        backgroundImagePath: '',
        type: 'sweets',
        bgSize: 'm-small',
        dishes: sweetsDishes,
    },
    '2028': {
        title: <>Про запас</>,
        description: (
            <>
                Подборка блюд для тех, кто готовит не каждый день, а по настроению! Здесь вы найдете наборы, которые можно хранить и готовить в течение 10-60 дней после доставки.
            </>
        ),
        backgroundImagePath: '',
        type: 'nz',
        bgSize: 'm-small',
    },
    breakfast: {
        title: <>Богемные завтраки</>,
        description: '',
        backgroundImagePath: '',
        type: 'breakfast',
        bgSize: 'm-small',
        categoryId: CATEGORY_BREAKFASTS,
    },
    // categories selections (new upsell BR-1131)
    [CATEGORY_MAIN as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_MAIN],
        tag: CATEGORY_MAIN,
        categoryId: CATEGORY_MAIN,
    },
    [CATEGORY_SOUPS as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_SOUPS],
        tag: CATEGORY_SOUPS,
        categoryId: CATEGORY_SOUPS,
    },
    [CATEGORY_SALADS as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_SALADS],
        tag: CATEGORY_SALADS,
        categoryId: CATEGORY_SALADS,
    },
    [CATEGORY_SNACKS as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_SNACKS],
        tag: CATEGORY_SNACKS,
        categoryId: CATEGORY_SNACKS,
    },
    [CATEGORY_BREAKFASTS as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_BREAKFASTS],
        tag: CATEGORY_BREAKFASTS,
        categoryId: CATEGORY_BREAKFASTS,
    },
    [CATEGORY_DESSERT as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_DESSERT],
        tag: CATEGORY_DESSERT,
        categoryId: CATEGORY_DESSERT,
    },
    [CATEGORY_UPSELL as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_UPSELL],
        tag: CATEGORY_UPSELL,
        categoryId: CATEGORY_UPSELL,
    },
    [CATEGORY_FARM_PRODUCTS as keyof TDishesSelectionRecord]: {
        title: CATEGORY_TITLES.ru[CATEGORY_FARM_PRODUCTS],
        tag: CATEGORY_FARM_PRODUCTS,
        categoryId: CATEGORY_FARM_PRODUCTS,
    },
};

export const getBannerContent = (firstTag: TActiveSelectionKeys | null): TDisheSelectionMeta => {
    if (!firstTag) {
        return DishesSelectionRecord['1220'];
    }
    return DishesSelectionRecord[firstTag];
};


// easter: {
//     title: <>Пасхальное<br />меню</>,
//     description: (
//         <>
//             Всё для праздника: от&nbsp;куличей и&nbsp;пасхи&nbsp;&mdash; до&nbsp;панеттоне и&nbsp;яиц с&nbsp;топингами
//         </>
//     ),
//     backgroundImagePath: easter.m,
//     type: 'easter',
//     bgSize: 'm-small',
//     dishes: easterDishes.filter((dishId) => dishId !== '4443'),
// },
// '2110': {
//     title: <>Праздничное<br />меню<br />8&nbsp;марта</>,
//     backgroundImagePath: special.m,
//     description: <>
//         Блюда по&nbsp;ресторанным рецептам: отборные мясо и&nbsp;рыба, авторские соусы и&nbsp;маринады, подача от&nbsp;шефа.
//     </>,
//     type: 'special',
//     bgSize: 'm-small',
// },
// '2121': {
//     title: <>Постное<br />меню</>,
//     backgroundImagePath: fasting.m,
//     description: <>Мы&nbsp;подобрали необычные и&nbsp;разнообразные блюда с&nbsp;растительным составом, чтобы помочь вам организовать питание в&nbsp;пост!</>,
//     type: 'fasting',
//     bgSize: 'm-small',
// },    // '1765': {
//     title: <>Ресторан<br />дома</>,
//     backgroundImagePath: homerest.m,
//     description: <>
//         Блюда по&nbsp;ресторанным рецептам: отборные мясо и&nbsp;рыба, авторские соусы и&nbsp;маринады, подача от&nbsp;шефа.
//     </>,
//     type: 'homerest',
//     bgSize: 'm-small',
// },

// school: {
//     title: <>Вкусная<br />переменка</>,
//     description: (
//         <>
//             Пицца из школьной столовки и котлета с пюрешкой — вкусы из детства. Мы выросли и по-прежнему любим эти блюда, но уже в новых версиях!
//         </>
//     ),
//     backgroundImagePath: school.m,
//     type: 'school',
//     bgSize: 'm-small',
//     dishes: schoolDishes,
// },
