import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    cartType,
} from 'types';

import formatPrice from 'app/utils/price';
import { LocaleContext } from 'app/containers/LocaleProvider';


const locales = {
    ru: {
        payTextLoading: 'Секундочку…',
        payTextCash: 'Оформить заказ',
        payTextCard: 'Оплатить',
    },
    en: {
        payTextCash: 'Order',
        payTextCard: 'Pay',
        payTextLoading: 'Please wait…',
    },
};


export function StepPayButtonContent(props) {
    const {
        cart,
        payLoading,
        paymentMethod,
    } = props;

    const { locale } = useContext(LocaleContext);

    const l = locales[locale];
    const payText = (() => {
        if (payLoading) {
            return l.payTextLoading;
        }
        if (paymentMethod === 'sbp') {
            return l.payTextCash;
        }
        return l.payTextCard;
    })();

    const originalPrice = (cart && cart.totals) ? cart.totals.total_price + cart.totals.delivery_price : null;
    const price = (cart && cart.totals) ? cart.totals.total_common_price : null;

    return (
        <>
            {payText}
            {Boolean(price) && (
                <span className="price">
                    <span className="num">{formatPrice(price)}</span>
                    <span className="rub">₽</span>
                </span>
            )}
            {Boolean(originalPrice) && originalPrice !== price && (
                <span className="original-price">
                    <span className="num">{formatPrice(originalPrice)}</span>
                    <span className="rub">₽</span>
                </span>
            )}
        </>
    );
}

StepPayButtonContent.propTypes = {
    cart: PropTypes.shape(cartType).isRequired,
    payLoading: PropTypes.bool,
    paymentMethod: PropTypes.string.isRequired,
};

StepPayButtonContent.defaultProps = {
    payLoading: false,
};
