import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import { ADDITIONAL_DISHES_CATEGORIES } from 'app/const/categories';
import pluralize from 'app/utils/pluralize';
import { withLocaleContext } from 'app/containers/LocaleProvider';
import {
    locales as umAliasLocales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';

import { UIButton, UIParagraph } from 'app/components/ui';
import MobileMainDishPriceLine from './elements/mobileMainDishPriceLine';

import './mobile-dish-description.scss';


const locales = {
    ru: {
        weight: 'г',
        min: 'минут',
        ready: 'Уже готово',
    },
    en: {
        weight: 'g',
        min: 'minutes',
        ready: 'Уже готово',
    },
};
class MobileDishDescription extends React.Component {
    // eslint-disable-next-line class-methods-use-this
    renderTitleContent() {
        const {
            localeContext: { locale },
            title,
            titleEn,
            caption,
            captionEn,
            toggleMobileDetails,
            isSoldOut,
        } = this.props;

        const dishTitle = locale === 'en' ? (titleEn || title) : title;
        const dishCaption = locale === 'en' ? (captionEn || caption) : caption;
        const soldOutCalsses = classNames({
            'is-soldout': isSoldOut,
        });

        return (
            <button
                type="button"
                aria-label="Показать описание"
                styleName="mobile-dish-desc__title-container"
                onClick={toggleMobileDetails}
            >
                <UIParagraph level="3.1" clip styleName={soldOutCalsses}>
                    {dishTitle}
                </UIParagraph>
                <UIParagraph level="3.1" ellipsis styleName={soldOutCalsses}>
                    {dishCaption}
                </UIParagraph>
            </button>
        );
    }

    renderAdditionalInfo() {
        const {
            localeContext: { locale },
            categoryId,
            weight,
            portion,
            cookingTime,
            umAlias: umAliasProp,
            isSoldOut,
            isDishCardWithCustomSelect,
            isBoxDish,
        } = this.props;

        // TODO: BR-1101 A/B TEST
        if (isDishCardWithCustomSelect || isBoxDish) {
            return null;
        }

        const umAlias = umAliasProp || ['г', 'г', 'г', 'г'];

        const cookingTimeInMin = Math.round(cookingTime / 60);

        const hasAlias = umAlias[0] !== 'г';
        const isAdditionalDish = ADDITIONAL_DISHES_CATEGORIES.includes(categoryId);
        const isRegularDish = !isAdditionalDish;

        const [UM_ALIAS_TYPE] = umAlias || [PORTION_UM_ALIAS_TYPE];
        const portionAliases = umAliasLocales[locale][UM_ALIAS_TYPE] || umAliasLocales[locale][PORTION_UM_ALIAS_TYPE];
        const um = pluralize(portion, portionAliases);
        const soldOutClasses = classNames({
            'is-soldout': isSoldOut,
        });

        const cookingTimeContainerClasses = classNames({
            'mobile-dish-desc__cooking-time-container': true,
            'is-soldout': isSoldOut,
        });

        return (
            <div styleName="mobile-dish-desc__additional-info-container">
                <div styleName={cookingTimeContainerClasses}>
                    <div styleName="mobile-dish-desc__cooking-time-texts">
                        <UIParagraph level="4.1" styleName={soldOutClasses}>
                            {/* eslint-disable no-nested-ternary */}
                            {cookingTimeInMin === 0 ? (
                                isRegularDish
                                    ? locales[locale].ready
                                    : hasAlias
                                        ? `${portion} ${um}`
                                        : (
                                            <>
                                                <span className="num">{cookingTimeInMin}</span>
                                                &nbsp;
                                                {locales[locale].min}
                                            </>
                                        )
                            ) : (
                                <>
                                    <span className="num">{cookingTimeInMin}</span>
                                    &nbsp;
                                    {locales[locale].min}
                                </>
                            )}
                            {/* eslint-enable no-nested-ternary */}
                        </UIParagraph>

                        <UIParagraph level="4.2" styleName={soldOutClasses}>
                            <span styleName="mobile-dish-desc__additional">
                                {!(cookingTimeInMin === 0 && !isRegularDish && hasAlias) && (
                                    <>
                                        &nbsp;/&nbsp;
                                        <span className="num">{portion}</span>
                                    </>
                                )}
                                &nbsp;х&nbsp;
                                <span className="num">{weight}</span>
                                {locales[locale].weight}
                            </span>
                        </UIParagraph>
                    </div>
                </div>
            </div>
        );
    }

    renderButton() {
        const {
            isSoldOut,
            portion,
            dishFormattedPrice,
            isBoxDish,
            onClick,
        } = this.props;

        return (
            <div styleName="mobile-dish-desc__button-container">
                <UIButton
                    onClick={onClick}
                    disabled={isSoldOut}
                    aria-label="Добавить в корзину"
                    data-test-id="dish-card__basket"
                    sizeType="medium"
                >
                    <MobileMainDishPriceLine
                        price={dishFormattedPrice}
                        portion={portion}
                        disabled={isSoldOut}
                        isBoxDish={isBoxDish}
                    />
                </UIButton>
            </div>
        );
    }

    render() {
        const { hasPestoAsAGift } = this.props;
        const rootClasses = classNames({
            'mobile-dish-desc__top-row': true,
            small: hasPestoAsAGift,
        });
        return (
            <div styleName={rootClasses}>
                <div styleName="mobile-dish-desc__desc-container">
                    {this.renderTitleContent()}
                    {this.renderAdditionalInfo()}
                </div>
                {this.renderButton()}
            </div>
        );
    }
}


export default withLocaleContext(MobileDishDescription);

MobileDishDescription.defaultProps = {
    hasPestoAsAGift: false,
};

MobileDishDescription.propTypes = {
    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    categoryId: PropTypes.string.isRequired,
    umAlias: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    dishFormattedPrice: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    portion: PropTypes.number.isRequired,
    cookingTime: PropTypes.number.isRequired,
    isSoldOut: PropTypes.bool.isRequired,

    onClick: PropTypes.func.isRequired,
    toggleMobileDetails: PropTypes.func.isRequired,

    nutrition: PropTypes.shape({
        calories: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isBoxDish: PropTypes.bool.isRequired,
    hasPestoAsAGift: PropTypes.bool,
};
