export const dishes = [
    '4663',
    '4662',
    '4664',
    '4658',
];

export const periods = [
    '2024-10-07',
    '2023-10-14',
];
