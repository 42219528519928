import React, {
    useCallback, useRef, useState, useLayoutEffect,
} from 'react';
import cn from 'classnames';
import isTomorrow from 'date-fns/is_tomorrow';

import { Mobile, Desktop } from 'app/components/Responsive';

import { getDateData } from 'app/utils/date';

import { ISODate, AvailableDate, Colors } from '../DatetimeSelect.types';

import arrow from './arrow.img.svg';
import arrowDisabled from './arrow.disabled.img.svg';
import css from './dateSelect.module.scss';


interface DateSelectProps {
    colors: Colors,
    disabled?: boolean,
    deliveryDates?: AvailableDate[],
    selectedDate: ISODate,
    responsive: boolean,
    onSelectDate: (nextDate: ISODate) => void, // eslint-disable-line no-unused-vars
}


const scrollingTime = 500;

type ScrollDirection = 'left' | 'right'

const scrollByTimeDelta = (timeDelta: number, el: HTMLElement, direction: ScrollDirection) => {
    const scrollDirectionModifier = {
        right: 1,
        left: -1,
    };

    const scrollDistance = el.clientWidth;

    const timeFraction = timeDelta / scrollingTime;
    const scrollValue = scrollDistance * timeFraction;
    const modifier = scrollDirectionModifier[direction];

    el.scrollBy(modifier * scrollValue, 0);
};

const animateScroll = (direction: ScrollDirection, el: HTMLElement) => {
    let startTime = 0;
    let prevTimeStamp = 0;

    return new Promise((resolve) => {
        const animateFrame = (timestamp: number) => {
            if (!startTime) {
                startTime = timestamp;
                prevTimeStamp = timestamp;
            }

            const elapsed = timestamp - startTime;
            const timeDelta = timestamp - prevTimeStamp;

            scrollByTimeDelta(timeDelta, el, direction);

            if (elapsed <= scrollingTime) {
                prevTimeStamp = timestamp;
                window.requestAnimationFrame(animateFrame);
            } else {
                resolve(true);
            }
        };

        window.requestAnimationFrame(animateFrame);
    });
};


export function DateSelect({
    colors,
    disabled = false,
    deliveryDates = [],
    selectedDate = '',
    responsive,
    onSelectDate,
}: DateSelectProps) {
    const scrollContainer = useRef<HTMLDivElement>(null);
    const scrollList = useRef<HTMLDivElement>(null);

    const [isScrolledToLeft, setIsScrolledToLeft] = useState(false);
    const [isScrolledToRight, setIsScrolledToRight] = useState(false);


    const resolveScrolledState = () => {
        if (!scrollContainer.current) return;
        if (!scrollList.current) return;

        const c = scrollContainer.current;
        const l = scrollList.current;

        const toLeft = c.scrollLeft < 1;
        const toRight = l.scrollWidth - (c.clientWidth + c.scrollLeft) < 1;

        setIsScrolledToLeft(toLeft);
        setIsScrolledToRight(toRight);
    };

    useLayoutEffect(() => {
        resolveScrolledState();
    }, []);


    const handleScrollBack = useCallback(async () => {
        if (!scrollContainer.current) return;
        await animateScroll('left', scrollContainer.current);
        resolveScrolledState();
    }, []);

    const handleScrollForward = useCallback(async () => {
        if (!scrollContainer.current) return;
        await animateScroll('right', scrollContainer.current);
        resolveScrolledState();
    }, []);


    const wrapperClasses = cn({
        [css.datesWrapper]: true,
        [css.responisveContainer]: responsive,
    });

    const scrollButtonsColorsClass = colors === 'white'
        ? css.dateScrollButtonWhite
        : css.dateScrollButtonTrasnparent;

    return (
        <div className={wrapperClasses}>
            {!disabled && (
                <Desktop>
                    <button
                        type="button"
                        className={cn([css.dateScrollButton, css.dateScrollButtonBack, scrollButtonsColorsClass])}
                        disabled={isScrolledToLeft}
                        onClick={handleScrollBack}
                    >
                        <img src={isScrolledToLeft ? arrowDisabled : arrow} alt="" />
                    </button>
                </Desktop>
            )}
            <div className={css.datesListContainer} ref={scrollContainer}>
                <div className={css.datesList} ref={scrollList}>
                    {deliveryDates.map((aDate) => {
                        const { date } = aDate;

                        const dateData = getDateData(date);
                        const title = isTomorrow(new Date(date)) ? 'Завтра' : dateData.weekDayShortName;
                        const subtitle = `${dateData.date} ${dateData.monthShortNameForDate}`;

                        const onClick = () => onSelectDate(date);

                        const buttonClasses = cn({
                            [css.dateSelectButton]: true,
                            [css.dateSelectButtonActive]: date === selectedDate,
                        });

                        const titleClasses = cn({
                            [css.dateSelectTitle]: true,
                            [css.dateSelectTitleWihte]: colors === 'white',
                            [css.active]: date === selectedDate,
                        });

                        const subtitleClasses = cn({
                            [css.dateSelectSubtitle]: true,
                            [css.dateSelectSubtitleWhite]: colors === 'white',
                            [css.active]: date === selectedDate,
                        });

                        return (
                            <button
                                type="button"
                                onClick={onClick}
                                disabled={disabled}
                                key={date}
                                className={buttonClasses}
                            >
                                <p className={titleClasses}>
                                    {title}
                                </p>
                                <p className={subtitleClasses}>
                                    {subtitle}
                                </p>
                            </button>
                        );
                    })}
                    <Mobile>
                        <div className={css.datesListSpacer} />
                    </Mobile>
                </div>
            </div>
            {!disabled && (
                <Desktop>
                    <button
                        type="button"
                        className={cn([css.dateScrollButton, css.dateScrollButtonForward, scrollButtonsColorsClass])}
                        disabled={isScrolledToRight}
                        onClick={handleScrollForward}
                    >
                        <img src={isScrolledToRight ? arrowDisabled : arrow} alt="" />
                    </button>
                </Desktop>
            )}
        </div>
    );
}
