/* eslint-disable import/order */
import React, {
    useState, useCallback, useEffect,
} from 'react';
import throttle from 'lodash/throttle';

import { errorService } from 'global/services';
import { requestSuggestions } from 'app/utils/suggestions';

import { UiKitInput } from '../../ui/input/UiKItUiInput';
import { AddressSuggestions } from '../Street/AddressSuggestions';

/* TYPES */
import { IUIKitInputProps, TEventObject } from 'app/components/ui/input/UiKItUiInput.types';

import css from './address-search.module.scss';
import { UIInput } from 'app/components/ui';

const throttledRequestSuggestions = throttle(requestSuggestions, 500);


// TODO: вынести TSuggestionData и TSuggestion ближе к app/utils/suggestions
type TSuggestionData = {
    house: string,
    region_with_type: string
    city_with_type: string
    area_with_type: string

};

export type TSuggestion = {
    /**
     * @param value - строковое значение адреса, отформатированное дадатой
    */
    value: string,
    /**
     *@param data - данные адреса, возвращаемые дадатой
     */
    data: TSuggestionData,
};


interface TAddressSearchProps extends IUIKitInputProps {
    subdivision?: string,
    onSubmit: (suggestion: TSuggestion) => void, // eslint-disable-line no-unused-vars
    errorText?: string,
    hasError?: boolean,
    placeholder?: string,
    isAddressDisabled?: boolean,
    isResponsive?: boolean,
    onSelectIncompleteAddress?: () => void,
}

export function AddressSearch({
    subdivision = undefined,
    onSubmit,
    externalInputRef,
    inputOnChangeHanlder,
    inputOnFocusHandler,
    inputOnBlurHanlder,
    inputOnClickHanlder,
    inputValue,
    inputUiState,
    buttonUiState,
    componentSize,
    inputLabel,
    isResponsive = false,
    errorText = 'Ошибка',
    hasError = false,
    isAddressDisabled = false,
    onSelectIncompleteAddress = () => { },
    placeholder = '',
}: TAddressSearchProps) {
    /* STATE ---------------------------------------------------------------- */
    const [suggestions, setSuggestions] = useState([]);
    const [isShownSuggestions, setIsShownSuggestions] = useState(false);
    const [currentLabel, setCurrentLabel] = useState(inputLabel);
    const [uiState, setUiState] = useState(inputUiState);

    const handleChangeAddress = useCallback(
        async (event: React.FormEvent<HTMLInputElement> | TEventObject) => {
            const { currentTarget: { value: nextValue } } = event;
            inputOnChangeHanlder(event);
            try {
                const { suggestions: nextSuggestions } = await throttledRequestSuggestions({ query: nextValue }, subdivision);
                const nextIsShownSuggestions = nextValue.length > 0;
                setSuggestions(nextSuggestions);
                setIsShownSuggestions(nextIsShownSuggestions);
            } catch (e) {
                errorService.log({
                    source: 'client',
                    text: 'AddressSearch error',
                    scope: 'AddressSearch',
                    erroro: e,
                });
            }
        }, [subdivision, inputOnChangeHanlder]);

    const handleSelectAddress = useCallback((item: TSuggestion) => {
        const { value: nextValue, data } = item;
        const haveOnlyOneSuggestions = suggestions.length === 1;

        const isAdressWithHouseSelected = Boolean(data && data.house);

        if (isAdressWithHouseSelected || haveOnlyOneSuggestions) {
            setIsShownSuggestions(false);
            onSubmit(item);
        } else {
            onSelectIncompleteAddress();
            handleChangeAddress({ currentTarget: { value: `${nextValue} ` } }).then((r) => r);
            if (externalInputRef?.current) {
                externalInputRef?.current?.focus();
            }
        }
    }, [externalInputRef, suggestions.length, handleChangeAddress, onSubmit, onSelectIncompleteAddress]);

    useEffect(() => {
        const closeHandler = (e: Event) => {
            const isClickOutside = !(e.target as HTMLElement).closest('[data-address-area]');
            if (isClickOutside) {
                setIsShownSuggestions(false);
            }
        };
        document.body.addEventListener('click', closeHandler);

        return () => {
            document.body.removeEventListener('click', closeHandler);
        };
    }, []);

    // Приходится следить за пропсами, т.к. стейты нужны выше для корректной работы нового UiKitInput
    useEffect(() => {
        if (hasError && !isAddressDisabled) {
            setUiState('error');
            setCurrentLabel(errorText);
        }
    }, [hasError, errorText, isAddressDisabled]);

    useEffect(() => {
        setCurrentLabel(inputLabel);
    }, [inputLabel]);

    useEffect(() => {
        setUiState(inputUiState);
    }, [inputUiState]);


    return (
        <div
            className={css.addressSearch}
            data-address-area="true"
        >
            {isResponsive
                ? (
                    <UiKitInput
                        inputValue={inputValue}
                        inputOnChangeHanlder={handleChangeAddress}
                        inputUiState={isAddressDisabled ? 'disabled' : inputUiState}
                        buttonUiState={isAddressDisabled ? 'disabled' : buttonUiState}
                        componentSize={componentSize}
                        inputLabel={currentLabel}
                        inputOnFocusHandler={inputOnFocusHandler}
                        inputOnBlurHanlder={inputOnBlurHanlder}
                        inputOnClickHanlder={inputOnClickHanlder}
                        externalInputRef={externalInputRef}
                        inputType="text"
                    />
                )
                : (
                    <div className={css.addressSearchInput}>
                        <UIInput
                            onChangeHandler={handleChangeAddress}
                            inputValue={inputValue}
                            onSubmitHandler={onSubmit}
                            onBlurHandler={inputOnBlurHanlder}
                            uiState={isAddressDisabled ? 'disabled' : uiState}
                            label={currentLabel || placeholder}
                            buttonType={buttonUiState}
                            inputRef={externalInputRef}
                            onFocusHanlder={inputOnFocusHandler}
                            passingEvent
                        />
                    </div>
                )}
            {isShownSuggestions && suggestions.length > 0 && (
                <div className={css.addressSearchSuggestions}>
                    <AddressSuggestions
                        suggestions={suggestions.slice(0, 3)}
                        onClickSuggestion={handleSelectAddress}
                    />
                </div>
            )}
        </div>
    );
}

// const [label, setLabel] = useState<string>(placeholder);
// const [inputUiState, setUiState] = useState<TInputUiState>('default');
// const [buttonUiState, setButtonUiState] = useState<TButtonUiState>('empty');

// console.log(inputUiState);

/* EFFECTS -------------------------------------------------------------- */
// useEffect(() => {
//     setUiState(initiaInputUiState);
//     setButtonUiState(initiaButtonUiState);
//     console.log(initiaInputUiState);
// },
// // NOTE didMount
// // eslint-disable-next-line react-hooks/exhaustive-deps
// [initiaInputUiState]);

// useEffect(() => {
//     if (isAddressDisabled) {
//         setUiState('disabled');
//     } else {
//         console.log(externalButtonUiState);
//         setUiState(externalInputUiState);
//         setButtonUiState(externalButtonUiState);
//     }
// }, [isAddressDisabled, externalInputUiState, externalButtonUiState]);

// useEffect(() => {
//     setValue(initialValue);
// }, [initialValue]);

// useEffect(() => {
//     if (hasError && !isAddressDisabled) {
//         setUiState('error');
//         setLabel(errorText);
//     }
// }, [hasError, errorText, isAddressDisabled]);

// const isErrorShown = hasError && !isShownSuggestions;

// const buttonType = isErrorShown || isShownSuggestions ? 'clear' : 'none';


